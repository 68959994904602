import React, { Component } from "react";
import FaceComponent from "./component/FaceComponent";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1> FACEMASH </h1>
        </header>
        <article>
          <FaceComponent
            leftEyeStyle={"90"}
            rightEyeStyle={"180"}
            skew={"2.5"}
            move={"25px,40px"}
          />
          <FaceComponent
            leftEyeStyle={"180"}
            rightEyeStyle={"780"}
            skew={"1"}
            move={"0px,0px"}
          />
          <FaceComponent
            leftEyeStyle={"270"}
            rightEyeStyle={"152"}
            skew={"0"}
            move={"25px,40px"}
          />
          <FaceComponent
            leftEyeStyle={"350"}
            rightEyeStyle={"900"}
            skew={"6"}
            move={"25px,40px"}
          />
          <FaceComponent
            leftEyeStyle={"10"}
            rightEyeStyle={"3000"}
            skew={"1"}
            move={"25px,40px"}
          />
        </article>
      </div>
    );
  }
}

export default App;
