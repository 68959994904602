import React, { Component } from "react";
import styles from "./FaceComponent.module.scss";

class Face extends Component {
  state = {
    eyeMove: true
  };

  clickEvent = () => {
    this.setState({
      eyeMove: !this.state.eyeMove
    });
  };

  render() {
    const leftEyeStyle = {
      transform: `rotate(${this.props.leftEyeStyle}deg)`
    };
    const rightEyeStyle = {
      transform: `rotate(${this.props.rightEyeStyle}deg) skewy(${
        this.props.skew
      }deg) scale(${this.props.skew}) translate(${this.props.move})`
    };

    const eyeNorm = {
      transform: `rotate(0deg) skewy(0deg) scale(1) translate(0px,0px)`
    };

    const activeLeftStyle = this.state.eyeMove ? eyeNorm : leftEyeStyle;
    const activeRightStyle = this.state.eyeMove ? eyeNorm : rightEyeStyle;
    return (
      <div onClick={this.clickEvent} className={styles.container}>
        <div style={activeLeftStyle} className={styles.leftEye} />
        <div style={activeRightStyle} className={styles.rightEye} />
        <h2>{this.props.eyeAngle}</h2>
      </div>
    );
  }
}

export default Face;
